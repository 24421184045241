import { Auth } from '@aws-amplify/auth';

const { origin } = window.location;
const redirectUrl = `${origin}/app`;

const config: Record<string, any> = {
  dev: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_Trac5E2KH',
    aws_user_pools_web_client_id: '6lfhcc9ji2q1fnd74ab6fvrsh3',
    oauth: {
      domain: 'auth-dev.teamexos.com',
      scope: ['openid'],
      redirectSignIn: redirectUrl,
      redirectSignOut: `${redirectUrl}/login`,
      responseType: 'code',
    },
  },
  preprod: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_XTZUekv22',
    aws_user_pools_web_client_id: 'lm8vn137ksmpg3lm194kk4qlp',
    oauth: {},
  },
  prod: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_31QwwmhDu',
    aws_user_pools_web_client_id: 'meiodgv8mk9m1ehq7dlml3p4k',
    oauth: {
      domain: 'auth.teamexos.com',
      scope: ['openid'],
      redirectSignIn: redirectUrl,
      redirectSignOut: `${redirectUrl}/login`,
      responseType: 'code',
    },
  },
};

Auth.configure(config[process.env.GATSBY_ENV_LEVEL || 'dev']);
