import React from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import { AuthContext, useAuth } from 'amplify-auth-hooks';

import theme from '../theme';

import PrinceSDKProvider from './PrinceSDK';

interface Props {
  children?: React.ReactNode;
}

const AUTH_UNINITIALIZED = 'uninitialized';

/**
 * P R O V I D E R S
 */
const Providers: React.FC<Props> = ({ children }: Props) => {
  const authProps = useAuth({});

  if (authProps.authState === AUTH_UNINITIALIZED) {
    return <div>Loading</div>;
  }

  return (
    <AuthContext.Provider value={authProps}>
      <PrinceSDKProvider>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </PrinceSDKProvider>
    </AuthContext.Provider>
  );
};

export default Providers;
