import { Reference, relayStylePagination } from '@apollo/client/utilities';
import { Auth } from '@aws-amplify/auth';
import type { ClientOptions } from '@teamexos/prince-sdk';
import EventEmitter from 'eventemitter3';

import fragments from '../graphql/fragments.json';

const clientEmitter = new EventEmitter();
const CLIENT_RECONNECTED = 'ws-reconnected';

export const subscribeToReconnect = (cb: () => void) => {
  clientEmitter.addListener(CLIENT_RECONNECTED, cb);

  return () => {
    clientEmitter.removeListener(CLIENT_RECONNECTED, cb);
  };
};

export const mergeNodes = (existing: Reference[], incoming: Reference[]) => {
  if (!existing) {
    return incoming;
  }

  if (!incoming) {
    return existing;
  }

  const existingRefs = existing.map(({ __ref }) => __ref);

  // dedupe any incoming and preserve order
  const incomingDeduped = incoming.filter(
    ({ __ref }) => !existingRefs.includes(__ref),
  );

  return [...existing, ...incomingDeduped];
};

export const mergeEdges = (
  existing: Array<{ node: Reference }>,
  incoming: Array<{ node: Reference }>,
) => {
  if (!existing) {
    return incoming;
  }

  if (!incoming) {
    return existing;
  }

  const existingRefs = existing.map(({ node: { __ref } }) => __ref);

  // dedupe any incoming chats and preserve order
  const incomingDeduped = incoming.filter(
    ({ node: { __ref } }) => !existingRefs.includes(__ref),
  );

  return [...existing, ...incomingDeduped];
};

export const clientOptions: ClientOptions = {
  getToken: async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken(),
  clientUrl: process.env.GATSBY_API_URL,
  wsUrl: process.env.GATSBY_WS_URL,
  onWsReconnected: () => {
    clientEmitter.emit(CLIENT_RECONNECTED);
  },
  apolloClientOptions: {
    name: 'graphql-playground',
    version: process.env.GATSBY_COMMIT_ID ?? 'unknown',
    connectToDevTools: process.env.NODE_ENV === 'development',
  },
  possibleTypes: fragments.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        usePush: {
          read: (existing: boolean | undefined) =>
            typeof existing === 'boolean' ? existing : true,
        },
      },
    },
    User: {
      fields: {
        chatRooms: relayStylePagination(['filters', 'orderBy']),
        coachedTeams: relayStylePagination(['filters']),
        activity: relayStylePagination(['filters']),
        currentPlan: {
          merge: true,
        },
      },
    },
    ActivityConnection: {
      fields: {
        nodes: {
          merge: mergeNodes,
        },
      },
    },
    UserTeamsConnection: {
      fields: {
        nodes: {
          merge: mergeNodes,
        },
      },
    },
    UserChatRoomsConnection: {
      fields: {
        // Required for the original member list (still used by Perform)
        nodes: {
          merge: mergeNodes,
        },
        // Required for member list v1 used by fit
        edges: {
          merge: mergeEdges,
        },
      },
    },
    ChatRoom: {
      fields: {
        messages: relayStylePagination(),
      },
    },
    ChatRoomMessagesConnection: {
      fields: {
        nodes: {
          merge(existing: Reference[], incoming: Reference[], { readField }) {
            const nodes: Record<string, Reference> = {};

            (existing ?? []).forEach((r) => {
              const key = readField('id', r);
              if (typeof key === 'string' || typeof key === 'number') {
                nodes[key] = r;
              }
            });
            (incoming ?? []).forEach((r) => {
              const key = readField('id', r);
              if (typeof key === 'string') {
                nodes[key] = r;
              }
            });

            // Sort messages by id
            return Object.values(nodes).sort(
              (a, b) =>
                Number(readField('id', a) ?? '') -
                (Number(readField('id', b)) ?? ''),
            );
          },
        },
      },
    },
  },
  debug: process.env.NODE_ENV === 'development',
};
